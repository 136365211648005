.shareContainer.blogContainer {
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
}
.likeContainer,
.saveContainer,
.commentContainer {
  display: flex;
  margin-right: 14px;
}

/* button {
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
} */

.updateBtnColor {
  background-color: #f3f3f4 !important;
  border-radius: 10px !important;
}
.updateBtnColor:hover {
  background-color: #e7e7e9 !important;
}
.heartBtn,
.saveBtn,
.giftBtn,
.shareBtn {
  /*padding: 0;*/
  height: 30px;
  min-width: 68px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  border: none;
  padding: 8px 10px;
  display: flex;
  align-items: center;
}

.heartBtn:hover,
.saveBtn:hover,
.giftBtn:hover,
.shareBtn:hover,
.commentBtn:hover {
  background: #e7e7e9;
}

.shareBtn {
  width: 70px;
  height: 26px;
}

.commentBtn svg {
  width: 18px !important;
  margin-right: 12px;
}
.giftBtn svg {
  margin-right: 8px;
}

.heartBtn .heart,
.saveBtn .save {
  /* padding: 5px 0 0 0; */
}

.shareBtn .share {
  padding: 5px 0 10px 10px;
}
.heartBtn svg path,
.shareBtn svg path,
.saveBtn svg path,
.updateBtn svg path {
  display: inline-block;
  fill: rgba(15, 13, 34, 0.8);
  stroke-width: 1;
  stroke: rgba(15, 13, 34, 0.8);
}

.giftBtn svg path {
  display: inline-block;
  fill: rgba(15, 13, 34, 0.8);
  stroke-width: 0.5;
  stroke: rgba(15, 13, 34, 0.8);
}

.updateBtn img {
  display: none;
}

.shareContainer.blogContainer div.blogShareIcons svg path {
  display: inline-block;
  fill: unset;
  stroke-width: 1.5;
}

/*social*/
.threeDotBtn {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: 0.3s;
}
.threeDotBtn circle {
  fill: white;
  transition: 0.3s;
}
.active,
.threeDotBtn:hover {
  width: 48px;
  height: 48px;
  background: #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.activeLike {
  width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.active circle,
.threeDotBtn:hover circle {
  fill: black;
}

.saveDesignContainer {
  width: 188px;
  height: 42px;
  margin: 4px auto;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.saveDesignContainer:hover {
  background-color: #e7e7e9;
  border-radius: 4px;
}

.saveDesign {
  display: flex;
  align-items: center;
  margin-left: 5px;
  width: 100%;
  height: 100%;
}
.saveDesign svg {
  margin-right: 7px;
}
.saveDesignTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #0f0d22;
}

.activeSave path {
  fill: #ff5e6d;
}

.activeSave .saveDesignTitle {
  color: #ff5e6d;
}

.heartBtn:hover path,
.saveBtn:hover path,
.shareBtn:hover path,
.updateBtn:hover path {
  fill: rgba(15, 13, 34, 1);
  stroke-width: 1;
  stroke: rgba(15, 13, 34, 1);
}

.giftBtn:hover path,
.commentBtn:hover path {
  fill: rgba(15, 13, 34, 1);
  stroke-width: 0.5;
}

.heartBtn.active path,
.saveBtn.active path,
.activeLike path {
  fill: #ff5e6d !important;
  stroke-width: 1 !important;
  stroke: #ff5e6d !important;
}

.heartBtn span,
.saveBtn span,
.giftBtn span,
.shareBtn span {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  color: #0f0d22;
}

.heartBtn:hover span,
.saveBtn:hover span,
.giftBtn:hover span,
.shareBtn:hover span {
  color: rgba(15, 13, 34, 1);
}

.heartBtn.active span,
.saveBtn.active span,
.activeLike span {
  color: #ff5e6d !important;
}

.shareBtn {
  padding: 0px;
}

.shareBoxContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.shareBox {
  position: absolute;
  min-width: 450px;
  max-width: 100%;
  /* height: 100px; */
  background-color: white;
  padding: 30px 50px 20px 50px;
  margin: 0 auto;
  top: 0px;
  left: 35%;
  text-align: center;
  border-radius: 5px;
  display: none;
}

.shareBox.hide {
  -webkit-animation: fadeOutNormal 0.6s;
  animation: fadeOutNormal 0.6s;
}

@-webkit-keyframes fadeOutNormal {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeOutNormal {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  from {
    height: 365px;
  }
  to {
    height: 0;
  }
}

@keyframes fadeOut {
  from {
    height: 365px;
  }
  to {
    height: 0;
  }
}

@-webkit-keyframes fadeIn {
  from {
    height: 0;
  }
  to {
    height: 365px;
  }
}

@keyframes fadeIn {
  from {
    height: 0;
  }
  to {
    height: 365px;
  }
}

.closeBtn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.closeBtn img {
  width: 16px;
  height: 16px;
  fill: #828282;
}

.shareBox h2 {
  /* H2-Heading-Bold-Black-24px */

  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  align-items: center;
  text-align: center;
  color: #000000;
  margin: 0 0 30px 0;
  padding: 0;
}

.shareContainer.blogContainer .shareBtn {
  background-color: transparent;
}

.commentBtn {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  padding: 4px 10px;
  display: flex;
  height: 22px;
  min-width: 44px;
  align-items: center;
  justify-content: flex-start;
}

.updateBtn {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  padding: 4px 10px;
  display: flex;
  height: 22px;
  min-width: 44px;
  align-items: center;
  justify-content: flex-start;
}

.updateBtn:hover {
  background: #e7e7e9;
}

.updateBtn svg {
  margin-right: 12px;
  zoom: 0.9;
}

.updateBtn span,
.commentBtn span {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  color: #0f0d22;
}

.updateBtn.active {
  background: #ffeaec;
  border-radius: 5px;
}

.updateBtn.active path {
  fill: #ff5e6d;
  stroke-width: 1;
  stroke: #ff5e6d;
}

.updateBtn.active span {
  color: #ff5e6d;
}

.socialContainer {
  display: flex;
  align-items: center;
}

/* .imgBtn > img {
  width: 18px !important;
  height: 18px !important;
  margin-right: 12px;
} */

.recentImg {
  border-radius: 50%;
  width: 30px !important;
  height: 30px !important;
}

.allimg {
  display: flex;
  align-items: center;
}

.allgiftBtn {
  cursor: pointer;
  display: flex;
  width: 180px;
  justify-content: space-between;
  align-items: center;
  padding: 5px 7px;
  background: #f3f3f4;
  border-radius: 100px;
}

.allgiftBtn span {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  color: #0f0d22;
  margin-right: 10px;
}

.allgiftBtn:hover {
  background: #e7e7e9;
}

@media only screen and (max-width: 1024px) {
  .updateBtn img {
    display: block;
  }

  .imgBtn svg {
    /* display: none; */
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .mediaContainer {
    width: 100%;
  }

  .shareContainer {
    display: inline-block;
  }

  .shareContainer.blogContainer {
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
  }

  .shareContainer .shareText,
  .giftText,
  .saveText {
    display: none !important;
  }

  .updateBtn {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 5px;
    padding: 4px 2px;
    display: flex;
    min-width: 55px;
    align-items: center;
    justify-content: center;
  }

  .likeContainer .updateBtn {
    min-width: 60px;
  }

  .likeContainer .updateBtn svg {
    margin-right: 5px;
    margin: 0;
  }

  .allgiftBtn {
    /* width: 115px; */
    margin-right: 5px;
  }
  .updateBtn svg {
    margin-right: 10px !important;
  }
  .imgBtn svg {
    margin-left: 19px !important;
  }

  .shareBox {
    position: fixed;
    /* min-width: 450px; */
    /* max-width: 100%; */
    min-width: 0px;
    max-width: 100%;
    /* height: 100px; */
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
    background-color: white;
    padding: 10px 0 60px 0;
    /* padding: 30px 50px 20px 50px; */
    margin: 0 auto;
    text-align: center;
    border-radius: 10px 10px 0 0;
    bottom: 0 !important;
    height: 550px;
    width: 100%;
    top: unset;
    left: unset;
    -webkit-animation: fadeIn 0.5s;
    animation: fadeIn 0.5s;
  }

  .shareBox.hide {
    -webkit-animation: fadeOut 0.6s;
    animation: fadeOut 0.6s;
  }

  .shareBox .shareHeader {
    font-size: 16px;
    /*box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);*/
    padding: 10px 0 15px 0;
    margin: 0;
    border-bottom: 1px solid #f2f2f2;
  }

  .closeBtn {
    position: absolute;
    top: 25px;
    left: 25px;
    cursor: pointer;
    width: 10px;
  }

  .likeContainer div {
    margin-left: 0px !important;
  }

  .heartBtn svg path,
  .shareBtn svg path,
  .saveBtn svg path {
    display: inline-block;
    fill: #c4c4c4;
    stroke-width: 1;
    stroke: #c4c4c4;
  }

  /*social*/

  .heartBtn:hover path,
  .saveBtn:hover path,
  .shareBtn:hover path,
  .commentBtn:hover path {
    fill: #c4c4c4;
    stroke-width: 1;
    stroke: #c4c4c4;
  }

  .heartBtn.active path,
  .saveBtn.active path {
    fill: #ff5e6d;
    stroke-width: 1;
    stroke: #ff5e6d;
  }
  .shareBtn {
    /*margin-top: 0px;*/
    margin-left: 10px;
    padding: 1px 5px 5px 10px;
  }

  .shareContainer.blogContainer .shareBtn {
    background-color: transparent;
  }
}

@media only screen and (max-width: 600px) {
  .allgiftBtn {
    cursor: pointer;
    display: flex;
    width: 180px;
    justify-content: space-between;
    align-items: center;
    padding: 5px 7px;
    background: #f3f3f4;
    border-radius: 100px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 5px;
  }

  .recentImg:not(:first-child) {
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
  }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .allgiftBtn {
    cursor: pointer;
    display: flex;
    width: 110px;
    justify-content: space-between;
    align-items: center;
    padding: 5px 7px;
    background: #f3f3f4;
    border-radius: 100px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 5px;
  }

  .likeContainer,
  .saveContainer {
    display: flex;
    margin-left: 2px;
  }
}

/* iphone 5 */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) {
  .shareBox {
    position: fixed;
    /* min-width: 450px; */
    /* max-width: 100%; */
    min-width: 0px;
    max-width: 100%;
    /* height: 100px; */
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
    background-color: white;
    padding: 10px 0 60px 0;
    /* padding: 30px 50px 20px 50px; */
    margin: 0 auto;
    text-align: center;
    border-radius: 10px 10px 0 0;
    bottom: 0 !important;
    height: 320px;
    width: 100%;
    top: unset;
    left: unset;
    -webkit-animation: fadeIn 0.5s;
    animation: fadeIn 0.5s;
  }
  @-webkit-keyframes fadeOut {
    from {
      height: 320px;
    }
    to {
      height: 0;
    }
  }

  @keyframes fadeOut {
    from {
      height: 320px;
    }
    to {
      height: 0;
    }
  }

  @-webkit-keyframes fadeIn {
    from {
      height: 0;
    }
    to {
      height: 320px;
    }
  }

  @keyframes fadeIn {
    from {
      height: 0;
    }
    to {
      height: 320px;
    }
  }
}
